/* FONTS */

@font-face {
  font-family: 'YuseiMagic';
  src: url('./assets/fonts/YuseiMagic-Regular.ttf') format('truetype');
  font-style: normal;
}

/* VARIABLE */

:root {
  --primary-black-color: #282C34;
  --main-color: #4381C1;
}

/* GENERAL */

html, body {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: 'YuseiMagic';
  color: var(--primary-black-color);
  font-size: 24px;
  line-height: 160%;
  text-align: left;
}

p span {
  color: var(--main-color);
}

header {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
}

header>div {
  display: flex;
  align-items: center;
}

.logo {
  width: 60px;
  margin-right: 20px;
}

header p {
  line-height: 22px;
  text-align: center;
}

header p b {
  font-size: 14px;
}

.container {
  width: 800px;
  max-width: 100%;
  text-align: center;
  margin: auto;
  margin-bottom: 100px;
  padding: 0 20px;
  box-sizing: border-box;
}

.form {
  text-align: left;
}

h4 {
  margin: 0;
  margin-top: 20px;
  text-transform: uppercase;
}

label {
  font-size: 18px;
}

input, textarea {
  border: 2px solid var(--primary-black-color);
  background: none;
  height: 50px;
  width: 100%;
  border-radius: 9px;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 10px;
  font-family: 'YuseiMagic';
  font-size: 16px;
}

textarea {
  min-height: 200px;
}

button {
  background: var(--primary-black-color);
  border: 2px solid var(--primary-black-color);
  border: none;
  color: white;
  font-family: 'YuseiMagic';
  height: 60px;
  width: 100%;
  font-size: 22px;
  border-radius: 9px;
  cursor: pointer;
  transition: all 0.3s;
}

button.secondary {
  background-color: transparent;
  border: 2px solid var(--primary-black-color);
  color: var(--primary-black-color);
}

button:disabled {
  opacity: 0.7;
  pointer-events: none;
  cursor: not-allowed;
}

button:hover {
  transform: scale(1.05);
}

.button-holder {
  display: flex;
}

.button-holder button {
  flex: 1;
}

.button-holder button:first-of-type {
  margin-right: 20px;
}

hr {
  border-color: var(--primary-black-color);
  border-width: 2px;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-center svg {
  margin-top: 12px;
  margin-left: 15px;
}

/* Game specific stuff */

.leaderboard {
  display: flex;
  text-align: left;
  border: 4px solid var(--primary-black-color);
  border-radius: 9px;
  box-sizing: border-box;
  margin-top: 50px !important;
}

.leaderboard div {
  flex: 1;
}

.leaderboard div:first-of-type {
  border-right: 4px solid var(--primary-black-color);
}

.leaderboard h4, .leaderboard p {
  margin-left: 38px;
}

@media (max-width: 700px) {

  .container {
    margin: auto;
    margin-bottom: 50px;
  }

  .leaderboard {
    flex-direction: column;
  }

  .leaderboard div:first-of-type {
    border-right: none;
    border-bottom: 4px solid var(--primary-black-color);
  }
  
  .leaderboard hr {
    display: none;
  }

}
